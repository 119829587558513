import * as React from "react";
import { ReactComponent as Splash } from "./assets/splash.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import text69 from "./assets/69.png";
import hamburger from "./assets/hamburger.png";

import {
  Section,
  Header,
  HeaderItems,
  SecondaryButton,
  Centered,
  Content,
  Flex,
  SectionHeader,
  CountBox,
  animatedBg,
  Div,
  FollowButton,
  BuyButton,
  FlexColumns,
  HeaderWrapper,
  HamburgerHeader,
  Timeline,
  HamburgerHeaderWrapper,
  HamburgerHeaderItems,
  Wrap,
  MAX_WIDTH,
  Meter,
  MobileCentered,
} from "./elements";

export const App: React.FC = () => {
  const [menuState, setMenuState] = React.useState(false);

  return (
    <Div
      style={{
        width: "100vw",
      }}
    >
      <Section>
        <Header>
          <HeaderWrapper>
            <BuyButton />
            <HeaderItems />
            <FollowButton />
          </HeaderWrapper>
          <HamburgerHeader>
            <HamburgerHeaderWrapper>
              <BuyButton />
              <Flex style={{ alignItems: "center" }}>
                <FollowButton />
                <img
                  src={hamburger}
                  style={{ height: 36, width: 36, marginRight: 18 }}
                  alt="hamburger"
                  onClick={() => setMenuState(!menuState)}
                />
              </Flex>
            </HamburgerHeaderWrapper>
            <HamburgerHeaderItems active={menuState} />
          </HamburgerHeader>
        </Header>

        <Centered style={{ paddingBottom: 100 }}>
          <Div>
            <Splash style={{ width: "100%" }} />
          </Div>
        </Centered>
        <Centered style={{ marginBottom: 240 }}>
          <Content>
            <p style={{ fontSize: "2em" }}>
              <b>$SETH</b> is bringing back a dash of nostalgia with a hint of
              culture by immortalising the severely underrated brilliance of
              Seth’s legendary dick doodles, spawned from the 2007 smash hit
              movie
              <b> Superbad</b>.
            </p>
            <Centered>
              <SecondaryButton style={{ height: 40, width: 150 }}>
                <a href="#buy" style={{ color: "white" }}>
                  HOW TO BUY
                </a>
              </SecondaryButton>
            </Centered>
          </Content>
        </Centered>
      </Section>

      <Section id="about">
        <Centered>
          <Content>
            <p style={{ fontSize: "2em" }}>
              Together, we pay homage and bring back to life these iconic,
              artistic renditions, merging the decades as we turn pencil to
              pixel, locking them on the blockchain for eternity.
            </p>
            <Wrap style={{ marginBottom: 24 }}>
              <img
                src={img1}
                alt="1"
                style={{ width: "100%", maxWidth: 550, aspectRatio: "auto" }}
              />
              <Flex
                style={{
                  maxWidth: MAX_WIDTH - 550,
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Div>
                  <h3>Token First, NFT Second</h3>
                  <p style={{ color: "#3E3E3E" }}>
                    NFT first, token drop second, this is the standard meta. We
                    are here to flip that meta on its head and ask the question,
                    why not Token launch first and THEN NFT drop?
                  </p>
                </Div>
                <Div>
                  <h3>No Public Sale or Whitelist</h3>
                  <p style={{ color: "#3E3E3E" }}>
                    There will be no public or whitelisted NFT mint, NFTs shall
                    be issued to token holders.
                  </p>
                </Div>
                <Div>
                  <h3>Raffle Airdrop</h3>
                  <p style={{ color: "#3E3E3E" }}>
                    1/1 pieces as seen in the movie will be airdropped on a
                    raffle basis, the more $SETH you hold the more entries to
                    the raffle.
                  </p>
                </Div>
                <Div>
                  <h3>More $SETH = More Dicks</h3>
                  <p style={{ color: "#3E3E3E" }}>
                    Remaining NFTs will be dropped in batches linked to $SETH
                    price progression. For example, 500 pieces may be dropped at
                    random to 500 wallets once token market cap hits $10MM,
                    followed by another batch at $20MM, et cetera.
                  </p>
                </Div>
              </Flex>
            </Wrap>
            <Wrap>
              <img
                src={img2}
                alt="2"
                style={{ width: "100%", maxWidth: 600, aspectRatio: "auto" }}
              />
              <img
                src={img3}
                alt="3"
                style={{ width: "100%", maxWidth: 360, aspectRatio: "auto" }}
              />
            </Wrap>
          </Content>
        </Centered>
      </Section>

      <Section id="buy" style={{ minHeight: "75vh" }}>
        <Centered style={{ marginBottom: 160 }}>
          <Content>
            <SectionHeader
              header="How to buy"
              subheaders={["Buy SETH on SOL"]}
            />
            <FlexColumns
              style={{
                gap: 15,
                marginBottom: 15,
              }}
            >
              <CountBox
                num={1}
                text="Create a SOL wallet with applications such as Phantom"
              />
              <CountBox
                num={2}
                text="Fund your wallet by sending SOL to your wallet"
              />
              <CountBox num={3} text="Paste CA into a dex such as raydium.io" />
            </FlexColumns>
            <Meter />
          </Content>
        </Centered>
      </Section>

      <Section style={{ minHeight: "33vh" }}>
        <Centered id="tokenomics">
          <Content style={{ marginBottom: 120 }}>
            <SectionHeader
              header="Tokenomics"
              subheaders={["A dick is for life. Not just for Christmas."]}
            />
          </Content>
        </Centered>
        <img
          src={text69}
          style={{ width: "100%", marginBottom: 120 }}
          alt="69"
        />
      </Section>

      <Section
        id="roadmap"
        style={{
          ...animatedBg,
          minHeight: "50vh",
        }}
      >
        <Centered style={{ paddingTop: 120 }}>
          <Content>
            <Div>
              <SectionHeader
                header="Roadmap"
                subheaders={["SETH is a grower.", "And a shower."]}
                color="white"
              />
              <Timeline />
            </Div>
          </Content>
        </Centered>
      </Section>

      <Section
        style={{
          backgroundColor: "black",
          color: "white",
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MobileCentered
          style={{ alignItems: "center", height: "75%", paddingTop: 120 }}
        >
          <h1 style={{ fontSize: "3em" }}>BUY</h1>
          <Logo style={{ padding: 15, width: 360 }} />
          <h1 style={{ fontSize: "3em" }}>NOW</h1>
        </MobileCentered>
        <Centered>
          <Content style={{ maxWidth: 2560, padding: "0 50px" }}>
            <hr className="solid" />
            <Flex style={{ justifyContent: "space-between", marginTop: 36 }}>
              <p style={{ color: "#B8B8B8", width: "60%", fontSize: "0.8em" }}>
                Disclaimer: The project has no link to Superbad or its creators,
                we are merely paying fun homage to their work. $SETH is a
                memecoin with no intrinsic value or expectation of finanical
                return.
              </p>

              <p style={{ color: "#B8B8B8", width: "40%", textAlign: "right" }}>
                © 2024 BY SETH. ALL RIGHTS RESERVED
              </p>
            </Flex>
          </Content>
        </Centered>
      </Section>
    </Div>
  );
};
