import * as React from "react";
import { keyframes, css } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import timeline from "./assets/timeline.png";
import { slideInDown, slideOutUp } from "react-animations";
import x from "./assets/x.png";
import wheel from "./assets/wheel.png";
import centerWheel from "./assets/center-wheel.png";

export const MAX_WIDTH = 960;
export const MOBILE = 320;

const size = {
  mobile: `${MOBILE}px`,
  tablet: "834px",
  desktop: "1440px",
};

export const mobile = (inner: CSSInterpolation) => css`
  @media (max-width: ${size.mobile}) {
    ${css(inner)};
  }
`;
export const tablet = (inner: CSSInterpolation) => css`
  @media (max-width: ${size.tablet}) {
    ${css(inner)};
  }
`;

const gradientStr =
  "linear-gradient(-45deg, #FF3535, #FF9898, #FDD470, #FFC998, #F07070)";

const gradient = keyframes({
  "0%": {
    backgroundPosition: "0% 50%",
  },
  "50%": {
    backgroundPosition: "100% 50%",
  },
  "100%": {
    backgroundPosition: "0% 50%",
  },
});

const changeColor = keyframes({
  "0%": {
    background: gradientStr,
  },
  "100%": {
    background: "black",
  },
});

const slideIn = keyframes`${slideInDown}`;
const slideOut = keyframes`${slideOutUp}`;

export const animatedBg: React.CSSProperties = {
  background: gradientStr,
  backgroundSize: "400% 400%",
  animation: `${gradient} 10s ease infinite`,
};

export const Div = styled.div();
export const P = styled.p();

export const PrimaryButton = styled.button(
  {
    ...animatedBg,
    cursor: "pointer",
    border: 0,
    color: "white",
    fontSize: "2em",
    fontWeight: 400,
    height: 60,
    width: 163,
    marginLeft: 18,
    borderRadius: 30,
    ":hover": {
      background: "black",
      animation: `${changeColor} 0.1s ease`,
    },
  },
  tablet({
    fontSize: "1.5em",
    width: 150,
    height: 40,
  }),
  mobile({
    fontSize: "1em",
    width: 75,
    height: 28,
  }),
);

export const BuyButton = () => (
  <PrimaryButton
    style={{
      letterSpacing: "0.2em",
      fontWeight: 500,
    }}
  >
    $SETH
  </PrimaryButton>
);

export const SecondaryButton = styled.button(
  {
    cursor: "pointer",
    background: "black",
    border: 0,
    color: "white",
    height: 60,
    width: 163,
    borderRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ":hover": {
      ...animatedBg,
    },
  },
  tablet({
    width: 150,
    height: 40,
  }),
  mobile({
    width: 75,
    height: 28,
  }),
);

export const FollowButton = () => {
  const Button = styled(SecondaryButton)(
    {
      marginRight: 18,
    },
    mobile({
      fontSize: "0.5em",
    }),
  );

  const Logo = styled.img(
    {
      width: 20,
      marginRight: 10,
    },
    tablet({
      width: 15,
    }),
    mobile({
      width: 10,
    }),
  );

  return (
    <Button>
      <Logo src={x} alt="x" />
      <span>FOLLOW</span>
    </Button>
  );
};

export const Header = styled.div(
  {
    height: 90,
  },
  tablet({
    // 61 instead of 60 to fix an off by one render
    height: 61,
    margin: 0,
    borderRadius: 0,
  }),
);

const HeaderItem = styled.a(
  {
    letterSpacing: "0.1em",
    margin: "0 10px",
    color: "black",
  },
  tablet({
    margin: "5px 10px",
  }),
);

const HeaderItemWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
});

const HamburgerItemWrapper = styled(HeaderItemWrapper)<{ active: boolean }>(
  ({ active }) => [
    tablet({
      display: active ? "flex" : "none",
      flexDirection: "column",
      animation: active ? `0.4s ${slideIn}` : `0.4s ${slideOut} forwards`,
    }),
  ],
);

const Items = () => (
  <>
    <HeaderItem href="#about">ABOUT</HeaderItem>
    <HeaderItem href="#buy">BUY</HeaderItem>
    <HeaderItem href="#tokenomics">TOKENOMICS</HeaderItem>
    <HeaderItem href="#roadmap">ROADMAP</HeaderItem>
  </>
);

export const HeaderItems = () => (
  <HeaderItemWrapper>
    <Items />
  </HeaderItemWrapper>
);

export const HamburgerHeaderItems: React.FC<{ active: boolean }> = ({
  active,
}) => (
  <HamburgerItemWrapper active={active}>
    <Items />
  </HamburgerItemWrapper>
);

export const HeaderWrapper = styled.div(
  {
    margin: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 45,
    height: "100%",
  },
  tablet({
    display: "none",
  }),
);

export const HamburgerHeader = styled.div(
  {
    display: "none",
  },
  tablet({
    background: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const HamburgerHeaderWrapper = styled.div({
  zIndex: 2,
  margin: "10px 0",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const Centered = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
});

export const MobileCentered = styled(Centered)(
  mobile({
    flexWrap: "wrap",
  }),
);

export const Content = styled.div(
  {
    maxWidth: MAX_WIDTH,
    width: "100%",
    margin: "0 120px",
  },
  tablet({
    margin: "0 40px",
  }),
  mobile({
    margin: 0,
  }),
);

export const Flex = styled.div({
  display: "flex",
});

export const Wrap = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
});

export const FlexColumns = styled.div(
  {
    display: "flex",
  },
  tablet({
    flexWrap: "wrap",
    justifyContent: "center",
  }),
);
export const SectionHeader: React.FC<{
  header: string;
  subheaders: string[];
  color?: string;
}> = ({ header, subheaders, color }) => (
  <Div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      ...(color ? { color } : {}),
    }}
  >
    <h4 style={{ ...(color ? { color } : {}) }}>{header}</h4>
    {subheaders.map((text, i) => (
      <p
        key={`${header}-${i}`}
        style={{
          fontSize: "2em",
          margin: 0,
        }}
      >
        {text}
      </p>
    ))}
  </Div>
);

export const Section = styled.div({
  minHeight: "100vh",
});

export const CountBox: React.FC<{ num: number; text: string }> = ({
  num,
  text,
}) => {
  const Box = styled.div(
    {
      ...animatedBg,
      width: 344,
      height: 320,
      borderRadius: 30,
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      color: "white",
    },
    tablet({
      width: 210,
      height: 195,
    }),
  );

  const Header = styled.h1(
    {
      justifyContent: "left",
      fontSize: "6em",
      margin: 40,
    },
    tablet({
      fontSize: "3em",
      margin: 20,
    }),
  );

  const Text = styled.p(
    {
      textAlign: "left",
      fontSize: "1.25em",
      margin: 40,
    },
    tablet({
      margin: 20,
      fontSize: "1em",
    }),
  );

  return (
    <Box>
      <Flex>
        <Header>{num}</Header>
      </Flex>
      <Flex>
        <Text>{text}</Text>
      </Flex>
    </Box>
  );
};

export const Meter = () => {
  const rotate = keyframes({
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  });

  const BlackBox = styled.div(
    {
      height: 372,
      width: "100%",
      background: "black",
      color: "white",
      position: "relative",
      borderRadius: 24,
      zIndex: -3,
      overflowX: "hidden",
      overflowY: "hidden",
    },
    tablet({
      height: 228,
    }),
  );

  const Title = styled.h1(
    {
      position: "absolute",
      top: 140,
      left: 40,
    },
    tablet({
      top: 90,
      fontSize: "1.5em",
    }),
    mobile({
      top: "15%",
      left: "20%",
    }),
  );

  const Center = styled.img(
    {
      zIndex: -1,
      position: "absolute",
      right: 250,
      bottom: -30,
      width: 120,
    },
    tablet({
      width: 80,
      right: 200,
      bottom: -20,
    }),
    mobile({
      width: 60,
      right: 120,
      bottom: -15,
    }),
  );

  const Wheel = styled.img(
    {
      zIndex: -2,
      position: "absolute",
      right: 80,
      bottom: -240,
      width: 480,
      animation: `${rotate} 10s linear infinite`,
    },
    tablet({
      width: 360,
      right: 60,
      bottom: -180,
    }),
    mobile({
      width: 300,
      right: 0,
    }),
  );

  return (
    <BlackBox>
      <FlexColumns>
        <Div>
          <Title>DICK-O-METER</Title>
        </Div>
        <Div>
          <Center src={centerWheel} alt="center-wheel" />
          <Wheel src={wheel} alt="wheel" />
        </Div>
      </FlexColumns>
      <Div
        style={{
          position: "absolute",
          top: 372,
          background: "white",
          width: "100%",
          height: 240,
        }}
      />
    </BlackBox>
  );
};

export const Timeline = () => {
  const Wrap = styled.div(
    {
      width: "100%",
      maxHeight: 320,
      overflowX: "auto",
      scrollbarWidth: "none",
    },
    mobile({}),
  );

  return (
    <Wrap>
      <img width={MAX_WIDTH} src={timeline} alt="timeline" />
    </Wrap>
  );
};
